import React, {useState, forwardRef, useImperativeHandle, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Modal, Overlay, Spinner, Tooltip} from 'react-bootstrap';

import {useCallback} from 'react';
import axios from "axios";
import {formatNumber} from "chart.js/helpers";
import {CopyToClipboard} from "react-copy-to-clipboard/src"; // Import at the top of your file
import {IMaskInput} from 'react-imask';
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from "@sentry/react";

import {useCookies} from "react-cookie";

const {REACT_APP_STAGE} = process.env;

const DoacaoSorteioModal = forwardRef((props, ref) => {

    const [donateModal, setDonateModal] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState("");

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [totalRaffle, setTotalRaffleValor] = useState(1);

    const [paymentMethod, setPaymentMethod] = useState(2);

    const [qrCodeBase64, setQrCodeBase64] = useState("");
    const [valor, setValor] = useState();
    const [pixCopyPaste, setPixCopyPaste] = useState("");
    const [donationCod, setDonationCod] = useState("");

    const [showCopy, setShowCopy] = useState(false);

    const [cpf_cnpj, setCpfCnpj] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [promoter] = useCookies(['promoter']);

    const filterName = (e) => {
        // Remove special characters, but keep accents
        const value = e.target.value.normalize();

        // " !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"
        //
        const sanitized = value.replace(/[^\w\s]/gi, '');
    }

    const setPhoneInput = (e) => {
        setPhone(e.target.value)
    }

    const [zipcode, setZipcode] = useState("");

    const [address, setAddress] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [terms, setTerms] = useState(false);
    const [termsError, setTermsError] = useState(false);

    const [zipcodeError, setZipcodeError] = useState(false);
    const [phoneError, setPhonecodeError] = useState(false);

    const [numbers, setNumbers] = useState([]);

    const numbersTags = numbers.map((number) => {
        return (
            <span className="badge bg-secondary m-1">{number}</span>
        )
    });

    const recaptchaRef = React.createRef();

    const [consultPaymentTime, setStartConsultPaymentTime] = useState();

    useEffect(() => {
        let interval;

        interval = setInterval(() => {
            // If page is 2 or consultPaymentTime is less 5 minutes, stop interval
            if (page === 2 && (new Date().getTime() - consultPaymentTime) < 300000) {
                consultPayment();
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        }
    });

    const consultPayment = function () {
        const data = {
            "Document": cpf_cnpj.replace(/\D/g, ''),
            "Email": email,
            "DonationCod": donationCod,
            "g-recaptcha-response": '',
            "CauseCod": 7,
        }

        axios.post(`https://${REACT_APP_STAGE}.ajudeumacausa.com.br/v1/raffle/GetMyRaffle`, data)
            .then(res => {

                setNumbers(res.data.Details.map((number) => {
                    return number.Number;
                }));

                setPage(3);

            }).catch((error) => {
        })
    }


    // Run a function every 5 seconds, stop after first successful request or after 5 minutes

    const consultZipCode = function (zipcode) {
        setZipcode(zipcode);

        if (zipcode.length === 9) {
            axios.get('https://viacep.com.br/ws/' + zipcode + '/json/')
                .then(response => {
                    if (response.data.erro) {
                        setZipcodeError(true);
                        return;
                    }

                    setZipcodeError(false)

                    if (response.data.logradouro === "") {
                        setAddress("Sem endereço");
                    } else {
                        setAddress(response.data.logradouro);
                    }

                    if (response.data.bairro === "") {
                        setDistrict("Sem bairro");
                    } else {
                        setDistrict(response.data.bairro);
                    }

                    setCity(response.data.localidade);
                    setState(response.data.uf);
                }).catch(err => {
                setZipcodeError(true);
                return;
            });
        }
    }

    // Validar e-mail
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // Validar CPF ou CNPJ
    const validateCpfCnpj = (value) => {
        const cpfCnpj = value.replace(/\D/g, '');

        if (cpfCnpj.length === 11) {
            return validateCpf(cpfCnpj);
        } else if (cpfCnpj.length === 14) {
            return validateCnpj(cpfCnpj);
        } else if (cpfCnpj.length < 11) {
            return true;
        } else {
            return false;
        }
    }

    function validateCnpj(cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (!cnpj || cnpj.length != 14
            || cnpj == "00000000000000"
            || cnpj == "11111111111111"
            || cnpj == "22222222222222"
            || cnpj == "33333333333333"
            || cnpj == "44444444444444"
            || cnpj == "55555555555555"
            || cnpj == "66666666666666"
            || cnpj == "77777777777777"
            || cnpj == "88888888888888"
            || cnpj == "99999999999999")
            return false
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho)
        var digitos = cnpj.substring(tamanho)
        var soma = 0
        var pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2) pos = 9
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado != digitos.charAt(0)) return false;
        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--
            if (pos < 2) pos = 9
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
        if (resultado != digitos.charAt(1)) return false
        return true;
    }

    function validateCpf(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf == '') return false;
        // Elimina CPFs invalidos conhecidos
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            return false;
        // Valida 1o digito
        let add = 0;
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
            return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(10)))
            return false;
        return true;
    }

    const [page, setPage] = useState(1);

    const [botaoPagar, setBotaoPagar] = useState(false);

    const paymentMethodClass = (paymentMethodElement) => {
        if (paymentMethod === paymentMethodElement) {
            return "btn btn-primary";
        } else {
            return "btn btn-light";
        }
    }

    const setShowToolTip = useCallback(() => {
        setShow(true);
        setTimeout(() => {
            setShow(false);
        }, 1000);
    });

    const copyPix = () => {
        navigator.clipboard.writeText(pixCopyPaste);
    }

    const limiteNumeros = 100;

    const handleNumerosChange = (e) => {
        // If value is not a number, set it to 1
        if (e.target.value === 0 || e.target.value === "0") {
            setTotalRaffleValor(1);
            return;
        }

        if (isNaN(e.target.value) || e.target.value === "") {
            setTotalRaffleValor(1);
            return;
        }

        // filter out non-digit characters
        const sanitized = e.target.value.replace(/\D/g, '');
        // remove leading zeros
        const value = parseInt(sanitized, 10);

        if (value > limiteNumeros) {
            setTotalRaffleValor(limiteNumeros);
            return;
        }
        setTotalRaffleValor(value);
    };

    const incrementNumeros = (quantity) => {
        if (totalRaffle >= limiteNumeros) {
            setTotalRaffleValor(limiteNumeros);
            return;
        }

        if (totalRaffle === 0) {
            setTotalRaffleValor(1);
            return;
        }

        // solve NaN issue
        if (isNaN(totalRaffle)) {
            setTotalRaffleValor(1);
            return;
        }

        if (totalRaffle === "") {
            setTotalRaffleValor(1);
            return;
        }

        if (totalRaffle + quantity > limiteNumeros) {
            setTotalRaffleValor(limiteNumeros);
            return;
        }

        setTotalRaffleValor(prevNumeros => prevNumeros + quantity);
    };

    const decrementNumeros = (quantity = 1) => {
        if (totalRaffle === 0) {
            setTotalRaffleValor(1);
            return;
        }

        if (totalRaffle === "") {
            setTotalRaffleValor(1);
            return;
        }

        if (isNaN(totalRaffle)) {
            setTotalRaffleValor(1);
            return;
        }

        if (totalRaffle - quantity < 1) {
            setTotalRaffleValor(1);
            return;
        }

        setTotalRaffleValor(prevNumeros => prevNumeros > 1 ? prevNumeros - quantity : 1);
    };

    useImperativeHandle(ref, () => ({
        handleModalOpen() {
            setDonateModal(true);
            if (page === 3) {
                setPage(1);
            }

            if (page === 2) {
                setStartConsultPaymentTime(new Date().getTime());
            }
        }
    }));

    function handleModalClose() {
        setDonateModal(false);
    }

    const nav = useNavigate();

    // submit form using axios

    const FormSubmit = (e) => {

        e.preventDefault();

        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }

        if (!terms) {
            setTermsError(true);
            return;
        }

        const transaction = Sentry.startTransaction({name: "raffle-buyraffle"});

        const data = {
            "Document": cpf_cnpj.replace(/\D/g, ''),
            "Email": email,
            "Name": name,
            "Telephone": phone,
            "Zipcode": zipcode.replace('-', ''),
            "PaymentMethod": paymentMethod,
            "TotalRaffle": totalRaffle,
            "PayAdministrativeFees": false,
            "TermsAndConditions": terms,
            "Address": address,
            "District": district,
            "City": city,
            "State": state,
            'grecaptcharesponse': '',
            'PromoterName': promoter.promoterName,
            'PromoterId': promoter.promoterId,
            'CauseCod': 7,
        }
        setBotaoPagar(true);

        const span = transaction.startChild({op: "axios-post"}); // This function returns a Span
        axios.post(`https://${REACT_APP_STAGE}.ajudeumacausa.com.br/v1/raffle/BuyRaffle`, data)
            .then(response => {

                if (response.data.ErroGeneric) {
                    setDisplayError(true);
                    setDisplayErrorMessage(response.data.ErroGeneric);

                    setTimeout(() => {
                        setDisplayError(false);
                        setDisplayErrorMessage("");
                    }, 5000);
                    return;
                }

                setValor(response.data.Value);
                setQrCodeBase64(response.data.QrCodeBase64);
                setPixCopyPaste(response.data.PixCopyPaste);
                setDonationCod(response.data.DonationCod);

                setPage(2);

                setStartConsultPaymentTime(new Date().getTime());

            }).finally(() => {
            setBotaoPagar(false);
        }).catch(err => {
            setDisplayError(true);
            setDisplayErrorMessage("Erro ao gerar Pix, tente novamente.");

            setTimeout(() => {
                setDisplayError(false);
                setDisplayErrorMessage("");
            }, 5000);
        }).finally(() => {
            span.finish();
            transaction.finish();
        });
    }

    return (
        <Modal className="fade modal-wrapper" id="modalDonate" show={donateModal} onHide={setDonateModal}
               backdrop="static" centered>
            <div className="modal-content">
                <div className={page === 1 ? "modal-content" : "d-none"}>
                    <div className="modal-header">
                        <h5 className="modal-title">Participe do sorteio</h5>
                        <button type="button" className="btn-close" onClick={() => handleModalClose()}><i
                            className="flaticon-close"></i></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => FormSubmit(e)}>
                            <div className="row">
                                <div className="col-lg-12 mb-2">
                                    <div className="tag-donate style-1 d-flex justify-content-center">
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(1)
                                                   }}
                                                   id="success-outlined-1" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-1">1 número</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(3)
                                                   }}
                                                   id="success-outlined-2" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-2">3 números</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(5)
                                                   }}
                                                   id="success-outlined-3" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-3">5 números</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="tag-donate style-1 d-flex justify-content-center">
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(10)
                                                   }}
                                                   id="success-outlined-4" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-4">10 número</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(15)
                                                   }}
                                                   id="success-outlined-5" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-5">15 números</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="btn-check" name="options-outlined"
                                                   onInput={() => {
                                                       setTotalRaffleValor(20)
                                                   }}
                                                   id="success-outlined-6" autoComplete="off"/>
                                            <label className="btn btn-sm btn-outline-success"
                                                   htmlFor="success-outlined-6">20 números</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group">
                                        <div className="input-group input-group-sm mb-3">
                                            <button className="btn btn-sm btn-outline-dark p-3" type="button"
                                                    id="button-remove-5"
                                                    onClick={() => decrementNumeros(5)}>-5
                                            </button>
                                            <button className="btn btn-sm btn-outline-dark p-3" type="button"
                                                    id="button-addon1"
                                                    onClick={() => decrementNumeros(1)}>-1
                                            </button>
                                            <input max={100} type="text" className="form-control form-control-sm"
                                                   placeholder="Números"
                                                   value={totalRaffle} onChange={handleNumerosChange}/>
                                            <button className="btn btn-sm btn-outline-dark p-3" type="button"
                                                    id="button-addon1"
                                                    onClick={() => {
                                                        incrementNumeros(1)
                                                    }}>+1
                                            </button>
                                            <button className="btn btn-sm btn-outline-dark p-3" type="button"
                                                    id="button-addon1"
                                                    onClick={() => {
                                                        incrementNumeros(5)
                                                    }}>+5
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input name="dzName" required="" type="text" maxLength="255"
                                                   className="form-control" placeholder="Nome Completo"
                                                   value={name} onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <IMaskInput
                                                mask={["000.000.000-00", "00.000.000/0000-00"]}
                                                className={validateCpfCnpj(cpf_cnpj) ? "form-control" : "form-control is-invalid"}
                                                value={cpf_cnpj}
                                                onChange={(e) => setCpfCnpj(e.target.value)}
                                                onComplete={(e) => setCpfCnpj(e)}
                                                placeholder="CPF/CNPJ"
                                                name="dzCpfCnpj" required
                                            ></IMaskInput>
                                            <div className="invalid-feedback">
                                                {cpf_cnpj.length > 14 ? "CNPJ inválido" : "CPF inválido"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input name="dzEmail"
                                                   required
                                                   maxLength={255}
                                                   type="text"
                                                   className={emailError ? "form-control is-invalid" : "form-control"}
                                                   placeholder="Email"
                                                   value={email}
                                                   onChange={(e) => setEmail(e.target.value.length > 255 ? e.target.value.substring(0, 255) : e.target.value)}
                                            />
                                        </div>
                                        <div className="invalid-feedback">
                                            E-mail inválido
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <IMaskInput
                                                mask={[{mask: '(00) 0000-0000'}, {mask: '(00) 00000-0000'}]}
                                                name="dzPhone"
                                                className={phoneError ? "form-control is-invalid" : "form-control"}
                                                placeholder="Telefone"
                                                required
                                                value={phone}
                                                onChange={(e) => setPhoneInput(e)}
                                                onComplete={(e) => setPhone(e)}
                                            >
                                            </IMaskInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <div className="form-group mb-0"
                                         style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '10px'}}>
                                        <input type="checkbox" className="form-check-input form-check-input-terms p-r5"
                                               name="terms"
                                               id="terms"
                                               value={terms} onChange={(e) => setTerms(e.target.checked)}
                                               required/>
                                        <div className="label-and-feedback">
                                            <label className="form-label px-2" htmlFor="terms">
                                                Li e aceito os <a className="fw-bold" target="_blank"
                                                                  href="https://cdn.ajudeumacausa.com.br/termos/termosdeuso-202312.pdf">termos</a> de
                                                uso
                                                e <a className="fw-bold" target="_blank"
                                                     href="https://cdn.ajudeumacausa.com.br/termos/privacidade-202312.pdf">condições
                                                de
                                                privacidade</a>.
                                            </label>
                                            <div className="invalid-feedback">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0 text-center py-2">
                                        <div
                                            className={(displayError ? "alert alert-danger" : 'd-none') + " fade show"}>{displayErrorMessage}</div>
                                        <Button variant="primary" type="submit" name="submit"
                                                className="btn btn-primary btn-block" disabled={botaoPagar}>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className={botaoPagar ? '' : 'd-none'}
                                            />
                                            {' '}
                                            Pagar R$ {totalRaffle * 30},00
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={page === 2 ? "modal-content" : "d-none"}>
                    <div className="modal-header">
                        <h5 className="modal-title">Dados para pagamento</h5>
                        <button type="button" className="btn-close" onClick={() => handleModalClose()}><i
                            className="flaticon-close"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className="col-lg-12"
                                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="form-group">
                                    <div className="input-group mb-0">
                                        <img src={"data:image/png;base64, " + qrCodeBase64} alt="QR Code"/>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={showCopy ? "alert alert-success fade show text-center my-2" : "alert fade d-none"}
                                role="alert">
                                Código copiado!<br/>
                            </div>
                            <div className="col-lg-12"
                                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="form-group mb-0">
                                    <div className="input-group mb-0">
                                        <CopyToClipboard text={pixCopyPaste} onCopy={() => {
                                            setShowCopy(true);
                                            setTimeout(() => {
                                                setShowCopy(false);
                                            }, 2000)
                                        }}>
                                            <button className="btn btn-primary btn-sm">Copiar código Pix
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-2"
                                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="row">
                                    <div className="col-lg-12"
                                         style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div className="form-group">
                                            <div className="input-group mb-0">
                                                <h5>Valor: R$ {formatNumber(valor, {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })},00</h5>
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="text-xl-center">
                                                    Intermediada por <b>AJUDE UMA CAUSA</b>
                                                    <br/>
                                                    CNPJ: <b>53.131.729/0001-63</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"
                                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className="form-group mb-0 text-center">
                                    <Button variant="primary" type="button" name="submit"
                                            className="btn btn-primary btn-block" onClick={() => {
                                        setPage(1)
                                    }}>
                                        {' '}
                                        Voltar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={page === 3 ? "modal-content" : "d-none"}>
                    <div className="modal-header">
                        <h5 className="modal-title">Números do sorteio</h5>
                        <button type="button" className="btn-close" onClick={() => handleModalClose()}><i
                            className="flaticon-close"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className={"col-lg-12"}>
                                <div className={"alert alert-success text-center"} role="alert">
                                    <p className={"mb-0"}>Obrigado pela sua doação!</p>
                                    <p className={"mb-0"}>Enviaremos para o seu e-mail todos os detalhes do
                                        sorteio.</p>
                                    <p className={"mb-0"}>Seus números da sorte são:</p>
                                </div>
                            </div>
                            <div className={"col-lg-12"}>
                                {numbersTags}
                            </div>
                        </div>
                    </div>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LeYASkpAAAAAEqY8ZBGuL-2Y2GRTPR1pF-fhQjl"
                />
            </div>
        </Modal>
    );
});
export default DoacaoSorteioModal;
